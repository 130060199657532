const {
  STAGE,
  SENTRY_DSN,
  MINERVA_URL,
  HERA_CLIENT_HOST,
  HACKLE_BROWSER_KEY,
} = window.__env__;

type Environment = {
  stage: string,
  sentryDsn: string,
  minervaUrl: string,
  heraClientHost: string,
  hackleBrowserKey: string,
};

export const env: Environment = {
  stage: STAGE,
  sentryDsn: SENTRY_DSN,
  minervaUrl: MINERVA_URL,
  heraClientHost: HERA_CLIENT_HOST,
  hackleBrowserKey: HACKLE_BROWSER_KEY,
};
