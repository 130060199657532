import * as Hackle from '@hackler/javascript-sdk';
import type { HackleEvent } from '@hackler/javascript-sdk';
import { env } from '@/exportables/constants/env';

const getHackleClient = (() => {
  let hackleClient: Hackle.HackleClient | null = null;

  return () => {
    if (hackleClient) {
      return hackleClient;
    }
    const config = {
      debug: ['alpha', 'local'].includes(env.stage),
    };
    hackleClient = Hackle.createInstance(env.hackleBrowserKey, config);
    return hackleClient;
  };
})();

const setHackleUser = (
  user: { userId: string, internalAccount: boolean } | undefined,
) => {
  const hackleClient = getHackleClient();
  if (hackleClient.getUser().userId !== user?.userId) {
    hackleClient.resetUser();

    user && hackleClient.setUserId(user.userId);
  }
  hackleClient.setUserProperties({
    // NOTE: Hackle SDK의 Property에는 어떠한 타입을 넣어도 된다고 typing 되어있으나,
    // boolean 값으로 false를 보내면, 해당 프로퍼티를 누락시키는 이슈가 있어서, String으로 보냄
    internal_account: user?.internalAccount || 'false',
  });
};

const addHackleClickEventListener = () => {
  window.document.body.addEventListener('click', (event) => {
    const target = (event.target as HTMLElement).closest(
      '[data-hackle-value]',
    ) as HTMLElement;

    if (!target) return;

    const hackleEventValue = target.dataset.hackleValue;

    if (hackleEventValue) {
      const parsedValue = /{.+}/.test(hackleEventValue)
        ? JSON.parse(hackleEventValue)
        : hackleEventValue;

      track(parsedValue);
    }
  });
};

interface HackleViewEvent extends Event {
  detail?: string | HackleEvent;
}

const addHackleTrackEventListener = () => {
  window.document.body.addEventListener(
    'hackle-view',
    (event: HackleViewEvent) => {
      const viewKey = event.detail;
      viewKey && track(viewKey);
    },
  );
};

const addHackleReadyEventListener = () => {
  const dispatchReadyEvent = () => {
    global.document.body.dispatchEvent(new CustomEvent('hackle-ready'));
  };

  window.document.body.addEventListener(
    'check-hackle-ready',
    dispatchReadyEvent,
  );

  dispatchReadyEvent(); // hackle event 전송 준비가 완료되었음을 알리는 이벤트
};

const trackCurrentPageView = () => {
  global.document.body
    .querySelectorAll('[data-hackle-view]')
    .forEach((element) => {
      const viewKey = (element as HTMLElement).dataset.hackleView;
      if (viewKey) {
        const parsedValue = /{.+}/.test(viewKey)
          ? JSON.parse(viewKey)
          : viewKey;

        track(parsedValue);
        (element as HTMLElement).removeAttribute('data-hackle-view');
      }
    });
};

const track = (hackleEvent: HackleEvent | string) => {
  const hackleClient = getHackleClient();
  hackleClient.onReady(() => {
    hackleClient.track(hackleEvent);
  });
};

type SignedInUser = {
  userId: string,
  internalAccount: boolean,
};
type UnSignedInUser = undefined;
export type HackleUser = SignedInUser | UnSignedInUser;

export const useHackle = (user: HackleUser) => {
  const initHackleEvent = () => {
    addHackleClickEventListener();
    addHackleTrackEventListener();
    addHackleReadyEventListener();
    trackCurrentPageView();
  };

  setHackleUser(user);

  return {
    initHackleEvent,
    trackView: dispatchHackleViewEvent,
  };
};

let gnbHackleReady: Promise<void> | null = null;

const checkGnbHackleReady = () => {
  const document = globalThis.window?.document;
  if (!document) return Promise.resolve();

  // hackle client 가 초기화 된 이후에 이벤트 전송을 보장하기 위해 hackle ready event 를 사용합니다.
  return new Promise<void>((resolve) => {
    document.body.addEventListener('hackle-ready', () => {
      resolve();
    });
    document.body.dispatchEvent(new CustomEvent('check-hackle-ready'));
  });
};

export const dispatchHackleViewEvent = (eventKey: HackleEvent | string) => {
  gnbHackleReady = gnbHackleReady || checkGnbHackleReady();
  gnbHackleReady.then(() => {
    document.body.dispatchEvent(
      new CustomEvent('hackle-view', { detail: eventKey }),
    );
  });
};
