import type { HackleUser } from '@/exportables/services/useHackle';
import { useHackle } from '@/exportables/services/useHackle';

(() => {
  const getHackleUserInfo = (): HackleUser => {
    const hackleInitInfoElement = document.getElementById('__hackle-init-info');
    const userId = hackleInitInfoElement?.dataset.userId || undefined;
    const internalAccount =
      hackleInitInfoElement?.dataset.internalAccount || undefined;

    return userId
      ? { userId, internalAccount: internalAccount === 'true' }
      : undefined;
  };

  window.document.addEventListener('DOMContentLoaded', () => {
    if (window.document.body.dataset.hackleEventBounded) return;
    window.document.body.dataset.hackleEventBounded = 'true';

    const hackleUser = getHackleUserInfo();
    const { initHackleEvent } = useHackle(hackleUser);

    initHackleEvent();
  });
})();
